<template>
  <b-card-actions action-collapse title="Liste des utilisateurs professionals">
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Recherche</label>
          <b-form-input
            placeholder="Recherche"
            type="text"
            class="d-inline-block"
            @input="advanceSearch"
          />
        </div>
      </b-form-group>
    </div>

    <vue-good-table
      ref="table-category"
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
        //  mode: 'records'
      }"
      theme="my-theme"
    >
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->
        <span v-if="props.column.field === 'fullName'" class="text-nowrap position-icon-text">
              <b-avatar
               variant="light-primary"
                class="badge-minimal"
                size="32"
                :src="props.row.image"
                :to="{ name: 'apps-users-view', params: { id: props.row._id } }"
              />
             <b-link
              :to="{ name: 'apps-users-view', params: { id: props.row._id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ props.row.fullName }}
            </b-link>
        </span>
        <span v-else-if="props.column.field === 'action'" class="text-nowrap">
             <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>


                   <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: props.row._id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Détailes</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-users-edit', params: { id: props.row._id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Modifier</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="props.row.deactivated==true">
              <feather-icon  icon="XIcon" />
              <span class="align-middle ml-50" @click="activate_desactivate(props.row)">Désactiver</span>
            </b-dropdown-item>
             <b-dropdown-item v-else>
              <feather-icon  icon="CheckIcon" />
              <span class="align-middle ml-50" @click="activate_desactivate(props.row)">Activer</span>
            </b-dropdown-item>
       <b-dropdown-item>
              <feather-icon  icon="XIcon" />
              <span class="align-middle ml-50" @click="boostUser(props.row)">Booster profile</span>
            </b-dropdown-item>
              </b-dropdown>
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Afficher 1 à </span>
            <b-form-select
              v-model="pageLength"
              :options="['3', '5', '10']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap"> sur {{ props.total }} résultats </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-actions>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";

import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  AppCollapse,
  AppCollapseItem,
  BFormSelect,
  BForm,
  BTooltip,
  BInputGroup,
  BInputGroupPrepend,
  BCardText,
  VBTooltip,
  ToastificationContent,
  feather,
  BFormGroup,
  BFormInvalidFeedback,
  BListGroup,
  BListGroupItem,
  BAlert,
  BModal,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { VueGoodTable } from "vue-good-table";

export default {
  components: {
    BCardActions,
    BCardHeader,
    BCardBody,
    AppCollapse,
    AppCollapseItem,
    VueGoodTable,
    BFormSelect,
    BForm,
    BTooltip,
    BInputGroup,
    BInputGroupPrepend,
    BCardText,
    VBTooltip,
    ToastificationContent,
    feather,
    BFormGroup,
    BFormInvalidFeedback,
    BListGroup,
    BListGroupItem,
    BAlert,
    BModal,
    VBModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  data() {
    return {
      pageLength: 5,
      searchTerm: "",
      columns: [
        {
          label: "Nom & prénom",
          field: "fullName",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Numéro de téléphone",
          field: "phoneNumber",
        },
        {
          label: "Cité",
          field: "city",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: [],
    };
  },
  watch:{
    $route (to, from){
      const urlTab = window.location.href.split('/');
      this.rows=[]
      let role =''
      if(urlTab[urlTab.length-1]=='Professional')
          role = 'Professional'
      else role = 'user'
      this.$store.getters.getCurrentUsers.forEach(element => {
       if(element.role==role){
           this.rows.push(element)
       }
      });
    }
  },
 
  created(){
    const urlTab = window.location.href.split('/');
    this.rows=[]
    let role =''
    if(urlTab[urlTab.length-1]=='professional')
      role = 'Professional'
    else role = 'user'
    this.$store.getters.getCurrentUsers.forEach(element => {
      console.log("test ",element)
      if(element.role==role){
          this.rows.push(element)
      }
    });
    
  },
  methods: {
    advanceSearch(val) {
      this.searchTerm = val;
    },
    activate_desactivate(item) {
    },
    boostUser(row){

    }
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/pages/ui-feather.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
